<template>
  <div class="base">
    <slot />
  </div>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>
</style>
